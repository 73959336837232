import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { State } from "./store";

const translations: { [lang: string]: { [key: string]: string } } = {
    ru: {
        hello: "Привет",
        hello_guest: "Привет, гость!",
        mobile_controls: "Мобильное управление",
        mirrored_controls: "Отразить управление",
        scale_controls: "Масштаб управления",
        enter_name_of_drive: "Введите название диска",
        ws_outdated: "Необходимо обновить DOS Браузер",
        update: "Обновить",
        preloading_sockdrive: "Загрузка",
        filter: "Фильтр",
        add: "Добавить",
        logout: "Выйти",
        features: "Функции",
        premium: "Подписка",
        buy: "Купить",
        experimental_features: "Доступ к экспериентальным функциям",
        cloud_saves: "Облачные сохранения",
        writeable_fat32: "FAT32 диски",
        net_no_limits: "Выбор сервера для стевой игры",
        unlock_options: "Доступ ко всем настройкам",
        error: "Упс... Что-то пошло не так...",
        consult_logs: "Проверьте логи браузера",
        bundle_loading: "Загрузка бандла",
        bundle_config: "Чтение конфигурации",
        try_free: "дней бесплатно",
        upload_file: "Выбирите архив jsdos/zip что бы начать...",
        emulation_backend: "Эмуляция",
        render_backend: "Рендер",
        render_aspect: "Соотношение сторон",
        worker: "Фоновый поток",
        mouse_sensitivity: "Чувст. мыши",
        mouse_lock: "Захват мыши",
        volume: "Громкость",
        pause: "Пауза",
        dosboxconf_template: "dosbox.conf шаблон:",
        preview: "Превью",
        continue: "Продолжить",
        skip: "Пропустить",
        loading: "Загрузка",
        extract_loading: "Распаковка архива",
        extract_long_time: "Может занять длительное время, пожалуйста ждите...",
        theme: "Тема",
        create_empty: "Создать пустой бандл",
        uploading_file: "Загрузка файла",
        fs_restart: "Вы хотите пересобрать бандл используя эту FS?",
        please_wait: "Пожалуйста подождите",
        making_bundle: "Создаем бандл",
        size: "Размер",
        room: "Команта",
        server: "Сервер",
        load_by_url: "Загрузить по ссылке",
        enter_url: "Введите ссылку",
        load: "Загрузить",
        stored: "Сохраненные",
        delete: "Удалить",
        editor: "Редактор",
        download: "Скачать",
        changes: "Сохранения",
        loading_saves: "Загрузка сохранений",
        success: "Успешно",
        success_save: "Сохранено в облаке",
        warn_save: "Cохранено в браузере (вы не вошли)",
        unable_to_save: "Ошибка записи",
        not_premium: "Подключить подписку",
        copy_net_link: "Отправьте ссылку на подключение",
        copied: "Скопировано",
        image_rendering: "Обработка изображения",
        read_only_access: "Эмуляция Win 9x в режиме только для чтения",
        fix: "Исправить",
        close: "Закрыть",
        cancle: "Отказаться",
        manage: "Управлять",
        unable_to_cancle_subscription: "Не удалось отменить подписку",
        subscription_cancelled: "Подписка отменена",
        hardware: "Аппаратное ускорение",
        net_drives: "Сетевые диски",
        fat_write: "FAT запись",
        play: "Запустить",
        system_cursor: "Системный курсор",
        no_cloud_access: "Введите",
        no_cloud_access2: "что бы использовать облачное хранилище",
        key: "Ваш ключ",
        sockdrive_native: "Native drives",
    },
    en: {
        hello: "Hello",
        hello_guest: "Hello, guest!",
        play: "Start",
        system_cursor: "System cursor",
        mobile_controls: "Mobile controls",
        mirrored_controls: "Mirror controls",
        scale_controls: "Scale controls",
        enter_name_of_drive: "Enter name of drive",
        ws_outdated: "Must update DOS Browser App",
        update: "Update",
        preloading_sockdrive: "Loading",
        filter: "Filter",
        add: "Add",
        logout: "Logout",
        features: "Features",
        premium: "Subscription",
        buy: "Buy",
        experimental_features: "Access to all experimental features",
        cloud_saves: "Cloud saves",
        writeable_fat32: "Writable FAT32",
        net_no_limits: "Change the network region",
        unlock_options: "Unlock all configuration options",
        error: "Oops... Something went wrong...",
        consult_logs: "Please check browser logs",
        bundle_loading: "Bundle loading",
        bundle_config: "Reading config",
        try_free: "days for free",
        upload_file: "Select jsdos/zip file to start...",
        emulation_backend: "Emulation",
        render_backend: "Render",
        render_aspect: "Aspect",
        worker: "Worker thread",
        mouse_sensitivity: "Mouse sens...",
        mouse_lock: "Capture mouse",
        volume: "Volume",
        pause: "Pause",
        dosboxconf_template: "dosbox.conf template:",
        preview: "Preview",
        continue: "Continue",
        skip: "Skip",
        loading: "Loading",
        extract_loading: "Unpacking archive",
        extract_long_time: "Can take long time, please be patient...",
        theme: "Theme",
        create_empty: "Create empty bundle",
        uploading_file: "Uploading file",
        fs_restart: "Do you want to rebuild the bundle using this fs?",
        please_wait: "Please wait",
        making_bundle: "Creating bundle",
        size: "Size",
        room: "Room",
        server: "Server",
        load_by_url: "Load by url",
        enter_url: "Enter url",
        load: "Load",
        stored: "Stored",
        delete: "Delete",
        editor: "Editor",
        download: "Download",
        changes: "Saves",
        loading_saves: "Loading saves",
        success: "Success",
        success_save: "Saved in cloud",
        warn_save: "Saved in browser (you are not logged)",
        unable_to_save: "Unable to save",
        not_premium: "Subscribe",
        copy_net_link: "Share this link to connect",
        copied: "Copied",
        image_rendering: "Image rendering",
        read_only_access: "Win 9x emulation is in READ ONLY mode",
        fix: "Fix",
        close: "Close",
        cancle: "Cancle",
        manage: "Manage",
        unable_to_cancle_subscription: "Unable to cancle subscription",
        subscription_cancelled: "Subscription cancelled",
        hardware: "Hardware acceleration",
        net_drives: "Net drives",
        fat_write: "Writeable FAT",
        no_cloud_access: "Please enter",
        no_cloud_access2: "to use cloud storage",
        key: "your key",
        sockdrive_native: "Native drives",
    },
    pt: {
        hello: "Olá",
        hello_guest: "Olá, convidado!",
        play: "Iniciar",
        system_cursor: "Cursor do sistema",
        mobile_controls: "Controles móveis",
        mirrored_controls: "Controles espelhados",
        scale_controls: "Escalar controles",
        enter_name_of_drive: "Digite o nome da unidade",
        ws_outdated: "Você precisa atualizar o aplicativo DOS Browser",
        update: "Atualizar",
        preloading_sockdrive: "Carregando",
        filter: "Filtrar",
        add: "Adicionar",
        logout: "Sair",
        features: "Recursos",
        premium: "Assinatura",
        buy: "Comprar",
        experimental_features: "Acesso a todos os recursos experimentais",
        cloud_saves: "Salvamentos em nuvem",
        writeable_fat32: "FAT32 gravável",
        net_no_limits: "Alterar a região da rede",
        unlock_options: "Desbloquear todas as opções de configuração",
        error: "Oops... Algo deu errado...",
        consult_logs: "Por favor, verifique os logs do navegador",
        bundle_loading: "Carregando pacote",
        bundle_config: "Lendo configuração",
        try_free: "dias grátis",
        upload_file: "Selecione um arquivo jsdos/zip para iniciar...",
        emulation_backend: "Emulador",
        render_backend: "Renderização",
        render_aspect: "Aspecto",
        worker: "Thread de trabalho",
        mouse_sensitivity: "Sensibilidade do mouse...",
        mouse_lock: "Capturar mouse",
        volume: "Volume",
        pause: "Pausar",
        dosboxconf_template: "Modelo dosbox.conf:",
        preview: "Pré-visualizar",
        continue: "Continuar",
        skip: "Pular",
        loading: "Carregando",
        extract_loading: "Extraindo arquivo",
        extract_long_time: "Pode levar algum tempo, por favor, seja paciente...",
        theme: "Tema",
        create_empty: "Criar pacote vazio",
        uploading_file: "Enviando arquivo",
        fs_restart: "Você deseja reconstruir o pacote usando este sistema de arquivos?",
        please_wait: "Por favor, aguarde",
        making_bundle: "Criando pacote",
        size: "Tamanho",
        room: "Sala",
        server: "Servidor",
        load_by_url: "Carregar por URL",
        enter_url: "Digite a URL",
        load: "Carregar",
        stored: "Armazenado",
        delete: "Excluir",
        editor: "Editor",
        download: "Download",
        changes: "Salvamentos",
        loading_saves: "Carregando salvamentos",
        success: "Sucesso",
        success_save: "Salvo na nuvem",
        warn_save: "Salvo no navegador (você não está conectado)",
        unable_to_save: "Não foi possível salvar",
        not_premium: "Assine",
        copy_net_link: "Compartilhe este link para se conectar",
        copied: "Copiado",
        image_rendering: "Renderização de imagem",
        read_only_access: "A emulação do Win 9x está no modo SOMENTE LEITURA",
        fix: "Corrigir",
        close: "Fechar",
        cancle: "Cancelar",
        manage: "Gerenciar",
        unable_to_cancle_subscription: "Não foi possível cancelar a assinatura",
        subscription_cancelled: "Assinatura cancelada",
        hardware: "Aceleração de hardware",
        net_drives: "Unidades de rede",
        fat_write: "FAT gravável",
        no_cloud_access: "Por favor, insira",
        no_cloud_access2: "para usar o armazenamento em nuvem",
        key: "sua chave",
        sockdrive_native: "Unidades nativas",
    },
};

const initialLang = navigator.language.startsWith("ru") ? "ru" :
    (navigator.language.startsWith("pt") ? "pt" : "en");

const initialState: {
    lang: "ru" | "en" | "pt",
    keys: { [key: string]: string },
} = {
    lang: initialLang,
    keys: translations[initialLang],
};

export type I18NState = typeof initialState;

export const i18nSlice = createSlice({
    name: "i18n",
    initialState,
    reducers: {
        setLang: (state, action: { payload: "ru" | "en" | "pt" }) => {
            state.lang = action.payload;
            state.keys = translations[action.payload];
        },
    },
});

export function useT() {
    const keys = useSelector((state: State) => state.i18n.keys);
    return (key: string) => {
        return keys[key] ?? key;
    };
};

export function getT(state: State) {
    return (key: string) => {
        return state.i18n.keys[key] ?? key;
    };
};
